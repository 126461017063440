import * as React from "react"
import Layout from "../components/layout";
import {GatsbyImage} from "gatsby-plugin-image";
import {styled} from "@mui/system";
import GradLine from "../static/svg/blue_grad_rect.svg"
import TwitterIcon from "../static/svg/twitter_icon.svg"
import LinkedInIcon from "../static/svg/linkedin_icon.svg"
import SnapchatIcon from "../static/svg/snap_icon.svg"
import YTIcon from "../static/svg/youtube_icon.svg"
import ArrowLeftIcon from "../static/svg/ArrowLeft.svg"
import {ButtonBase} from "@mui/material";
import {graphql} from "gatsby";
import GridLines from "../components/gridLines";
import { Link } from "gatsby"
import Seo from "../components/seo"

const Wrap = styled("div")({
  background: "#F8F8F8",
  display: "grid",
  gridTemplateAreas: `"image info"
                       "socials info"
                       "button button"
                       `,
  width: "100%",
  maxWidth: "1280px",
  margin: "28px auto",
  marginBottom: 125,
  "@media(min-width: 1024px)": {
    gridTemplateRows: "621px auto",
    gridTemplateColumns: "33% 1fr",
  },

  "@media(max-width: 576px)": {
    marginTop: 86,
    marginBottom: 81,
    gridTemplateAreas: `"image"
                        "info"
                        "socials"
                        "button"
    `
  }
})

const Photo = styled("div")({
  "@media(max-width: 576px)":{
    aspectRation: "390/502"
  },
  "& > *": {
    width: "100%"
  }
})

const Contacts = styled("div")({
  gridArea: "socials",
  padding: "71px 40px",
  display: "flex",
  flexDirection: "column",
  background: "#F2F2F2",
  gap: 32,
  justifyContent: "flex-start",

  "@media(max-width: 576px)": {
    padding: "50px 20px"
  }
})

const Caption = styled("div")({
  fontFamily: "Heiti TC",
  fontWeight: 400,
  lineHeight: "100%",
  letterSpacing: "-0.01em",
  display: "flex",
  gap: 17,
  color: "#0870E0",
  alignItems: "center",
})

const SocialNetworks = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent:"flex-start",
  gap: 51,
  "@media(max-width: 576px)": {
    gap: 30,
    justifyContent: "space-between",
    "& > svg": {
      height: 25
    }
  }
})

const ContactsCaption = styled(Caption)({
  fontSize: 24,

  "@media(max-width: 576px)": {
    fontSize: 15
  },
})

const InfoWrap = styled("div")({
  gridArea: "info",
  padding: "88px 58px",
  width: "100%",
  "@media(max-width: 576px)": {
    padding: "45px 20px"
  }
})

const Title = styled(Caption)({
  fontSize: "66px",
  "@media(max-width: 576px)": {
    fontSize: "28px"
  }
})

const Position = styled("div")({
  fontFamily: "Merriweather",
  lineHeight: "150%",
  color: "#222222",
  fontSize: 28,
  marginTop: 28,

  "@media(max-width: 576px)": {
    fontSize: 16,
    marginTop: 11,
  }
})

const Info = styled("div")({
  whiteSpace: "pre-line",
  marginBottom: 28,
  fontFamily: "Merriweather",
  color: "#222222",
  fontSize: 22,
  lineHeight: "190%",
  "@media(max-width: 576px)": {
    fontSize: 16
  },
  "&:last-of-type": {
    marginBottom: 0,
  },
})

const BioWrap = styled("div")({
  marginTop: 38,
})

const BackButton = styled(ButtonBase)({
  gridArea: "button",
  height: "120px",
  width:"100%",
  display: "flex",
  alignItems: "center",
  gap: 18,
  background: "linear-gradient(107.14deg, #0870E0 5.78%, #54A3F9 93.9%)",
  fontFamily: "Heiti TC",
  fontSize: 33,
  fontWeight: 400,
  letterSpacing: "-0.01em",
  color: "#FFFFFF",
  textTransform: "uppercase",
  "@media(max-width: 576px)": {
    fontSize: 18,
    gap: 10,
    height: 60
  }

})

const ArrowLeft = styled(ArrowLeftIcon)({
  height: 28,

  "@media(max-width: 576px)": {
    height: 20
  }
})

const TeamMemberCard = ({data: {allStrapiOurTeamMember}}) => {
  const memberData = allStrapiOurTeamMember.nodes[0]
  const bio = memberData.biography?.data?.biography.split("\n\n")
  return (
    <>
      <Layout headerDark={false}>
        <Seo title={memberData.name} />
        <Wrap>
          <Photo>
            <GatsbyImage style={{height: "100%"}} alt={memberData.name} image={memberData.profile_photo.localFile.childImageSharp.gatsbyImageData}/>
          </Photo>
          <InfoWrap>
            <div>
              <Title>
                <div style={{flexShrink: 0}}>
                  {memberData.name}
                </div>
                <GradLine/>
              </Title>
              <Position>
                {memberData.position}
              </Position>
            </div>
            <BioWrap>
            {
              bio.map(paragraph => (
              <Info>
                {paragraph}
              </Info>
              ))
            }
            </BioWrap>
          </InfoWrap>
          <Contacts>
            <ContactsCaption>
              <div style={{flexShrink: 0}}>
                JOIN CONVERSATION
              </div>
              <GradLine/>
            </ContactsCaption>
            <SocialNetworks>
              {memberData.twitter &&
                <a target="_blank" rel="noopener noreferrer" href={memberData.twitter}>
                  <TwitterIcon/>
                </a>
              }
              {
                memberData.linkedIn &&
                <a target="_blank" rel="noopener noreferrer" href={memberData.linkedIn}>
                  <LinkedInIcon/>
                </a>
              }
              {memberData.snapchat &&
                <a target="_blank" rel="noopener noreferrer" href={memberData.snapchat}>
                  <SnapchatIcon/>
                </a>
              }
              {memberData.youtube &&
                <a target="_blank" rel="noopener noreferrer" href={memberData.youtube}>
                  <YTIcon/>
                </a>
              }
            </SocialNetworks>
          </Contacts>
            <Link to={"/team"} style={{gridArea: "button", textDecoration: "none"}}>
              <BackButton>
                <ArrowLeft/>
                <div>
                  Back to team
                </div>
              </BackButton>
            </Link>
        </Wrap>
        <GridLines styles={{
          height: 87,
          heightMob: 30,
        }}/>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query TeamBioQuery($strapi_id: Int!) {
    allStrapiOurTeamMember(filter: {strapi_id: {eq: $strapi_id}}) {
    nodes {
      name
      strapi_id
      position
      twitter
      linkedIn
      snapchat
      youtube
      profile_photo {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      biography {
        data {
          biography
        }
      }
    }
  }
  }
`

export default TeamMemberCard